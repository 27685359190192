import * as React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Joselo from "../images/joselo-mercado.png";

// markup
const LuminaresPage = () => {
  function resize(event) {
    const frames = document.getElementsByTagName("iframe");
    console.log(["triggered"]);
    Array.from(frames).forEach((el) => {
      const ratio = 1.777777777777778;
      const parentDivWidth = el.offsetWidth;
      const newHeight = parentDivWidth / ratio;
      el.height = newHeight;
    });
  }

  React.useEffect(() => {
    resize();
  }, []);

  if (typeof window !== 'undefined') {
    window.addEventListener("resize", resize);
  }


  return (
    <>
      <Header></Header>
      <div className="Index-page-content">
        <div className="sqs-layout sqs-grid-12 columns-12">
          <div className="row sqs-row" id="yui_3_17_2_1_1633227834863_306">
            <div
              className="col sqs-col-12 span-12"
              id="yui_3_17_2_1_1633227834863_305"
            >
              <div
                className="sqs-block html-block sqs-block-html"
                data-block-type="2"
                id="block-56b231af4d088e92779c5d21"
              >
                <div className="sqs-block-content">
                  <h1 style={{ textAlign: "center" }}>Luminares 2021</h1>
                </div>
              </div>

              <div
                className="sqs-block -block sqs-block-"
                data-aspect-ratio="2.2"
                data-block-type="21"
                id="block-yui_3_17_2_19_1454526015148_21520"
              >
                <div
                  className="sqs-block-content sqs-intrinsic"
                  id="yui_3_17_2_1_1633227834863_134"
                >
                  &nbsp;
                </div>
              </div>

              <div className="row sqs-row">
                <div className="col sqs-col-2 span-2">
                  <div className="sqs-block -block sqs-block-spacer sized vsize-1">
                    <div className="sqs-block-content">&nbsp;</div>
                  </div>
                </div>
                <div className="col sqs-col-8 span-8">
                  <div className="row sqs-row">
                    <div className="col sqs-col-3 span-3">
                      <div
                        className="sqs-block html-block sqs-block-html"
                        data-block-type="2"
                        id="block-yui_3_17_2_22_1455045805581_72424"
                      >
                        <div className="sqs-block-content">
                          <img src={Joselo} />
                        </div>
                      </div>
                    </div>
                    <div className="col sqs-col-5 span-5">
                      <div
                        className="sqs-block html-block sqs-block-html"
                        data-block-type="2"
                        id="block-yui_3_17_2_22_1455045805581_73767"
                      >
                        <div className="sqs-block-content">
                          <p>
                            José Mercado (Joselo) es miembro del concilio de
                            Coalición por el Evangelio. Oriundo de Puerto Rico,
                            renuncia a su carrera de consultoría en el año 2006
                            para ingresar al colegio de pastores de Sovereign
                            Grace Ministries. Es el pastor principal de la
                            Iglesia Gracia Soberana en Gaithersburg, Maryland.
                            José completó su Maestría en Artes en estudios
                            teologícos en SBTS, y está casado con Kathy Mercado
                            y es padre de Joey y Janelle.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col sqs-col-2 span-2">
                  <div className="sqs-block spacer-block sqs-block-spacer sized vsize-1">
                    <div className="sqs-block-content">&nbsp;</div>
                  </div>
                </div>
              </div>

              <div className="row sqs-row">
                <div className="sqs-block html-block sqs-block-html">
                  <h2 style={{ textAlign: "center" }}>Sesiones</h2>

                  <div className="row sqs-row">
                    <div className="col sqs-col-6 span-6">
                      <div className="sqs-block">
                        <div className="video">
                          <iframe
                            src={"https://www.youtube.com/embed/T3guEujbvvs"}
                            title={"Sesion 1"}
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            frameBorder="0"
                            webkitallowfullscreen="true"
                            mozallowfullscreen="true"
                            allowFullScreen
                            width={"100%"}
                            height={232}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col sqs-col-6 span-6">
                      <div className="sqs-block">
                        <div className="video">
                          <iframe
                            src={"https://www.youtube.com/embed/kBoTH5iz_y8"}
                            title={"Sesion 1"}
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            frameBorder="0"
                            webkitallowfullscreen="true"
                            mozallowfullscreen="true"
                            allowFullScreen
                            width={"100%"}
                            height={232}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row sqs-row">
                <div className="sqs-block html-block sqs-block-html">
                  <div className="row sqs-row">
                    <div className="col sqs-col-6 span-6">
                      <div className="sqs-block">
                        <div className="video">
                          <iframe
                            src={"https://www.youtube.com/embed/N5vruxrCAwk"}
                            title={"Sesion 1"}
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            frameBorder="0"
                            webkitallowfullscreen="true"
                            mozallowfullscreen="true"
                            allowFullScreen
                            width={"100%"}
                            height={232}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col sqs-col-6 span-6">
                      <div className="sqs-block">
                        <div className="video">
                          <iframe
                            src={"https://www.youtube.com/embed/3Hg605Z9Wlk"}
                            title={"Sesion 1"}
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            frameBorder="0"
                            webkitallowfullscreen="true"
                            mozallowfullscreen="true"
                            allowFullScreen
                            width={"100%"}
                            height={232}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sqs-block spacer-block sqs-block-spacer">
                <div className="sqs-block-content sqs-intrinsic">&nbsp;</div>
              </div>

              <div className="sqs-block spacer-block sqs-block-spacer sized vsize-1">
                <div className="sqs-block-content">&nbsp;</div>
              </div>

              {/* <div></div> */}
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </>
  );
};

export default LuminaresPage;
